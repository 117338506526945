<template>
  <div class="container">
    <div class="header">
      <img src="../../assets/news/tt@2x.png" alt="" />
    </div>
    <div class="content">
      <div class="message">
        <div class="title">董事长寄语</div>
        <div class="line"></div>
        <div class="en-title">Chairman message</div>
        <div class="message-content">
          <img class="meeting" src="../../assets/news/dszjy.png" alt="" />
          <div class="card">
            <div class="word">
              <span style="display: inline-block; width: 0.33rem"></span>
              在不断变化的市场环境中，我们必须保持敏锐的洞察力和创新精神。我鼓励大家勇于尝试新的思路和方法，推动公司不断创新，以适应市场的变化和需求。
              我们的成功离不开客户的信任和支持。我希望我们每个人都能以客户为中心，提供优质的产品和服务，不断超越客户的期望，建立长期的合作关系。
              团队合作是实现公司目标的关键。我鼓励大家相互支持、协作，共同努力，共享成功。只有通过团队的力量，我们才能取得更大的成就。
            </div>
            <div class="sign">
              <div>
                <span>董事局主席</span>
              </div>
              <div>
                <img src="../../assets/news/pm.png" alt="" />
              </div>
            </div>
            <img class="img" src="../../assets/news/cs.png" alt="" />
          </div>
        </div>
      </div>
      <div class="news">
        <div class="title">公司新闻</div>
        <div class="line"></div>
        <div class="en-title">
          <div>Company News</div>
          <div class="more" @click="viewMore('公司新闻')">
            <div>查看更多 ></div>
            <div class="line"></div>
          </div>
        </div>

        <div class="news-content">
          <div class="left">
            <!-- <img src="../../assets/news/t1.png" alt="" /> -->
            <img :src="newsList[0].coverImage" alt="">
            <div class="news-name" @click="showDetail(1)">
              <div>{{ newsList[0].title }}</div>
              <img src="../../assets/news/jt23.png" alt="" />
            </div>
            <div
              :class="
                index === 1 && isShow
                  ? 'view-detail view-detail-show'
                  : 'view-detail'
              " 
              @click="isShow = false"
            >
              <div>{{ newsList[0].title }}</div>
              <div class="ellipsis">{{ newsList[0].intro }}</div>
              <div>
                <span @click="viewNewsDetail(newsList[0],0)">查看全文 </span>
                <img src="../../assets/news/jt22.png" alt="" />
              </div>
            </div>
          </div>
          <div class="middle">
            <div>
              <!-- <img src="../../assets/news/t2.png" alt="" /> -->
              <img :src="newsList[1].coverImage" alt="">
              <div class="news-name" @click="showDetail(2)">
                <div>{{ newsList[1].title }}</div>
                <img src="../../assets/news/jt23.png" alt="" />
              </div>
              <div
                :class="
                  index === 2 && isShow
                    ? 'view-detail view-detail-show'
                    : 'view-detail'
                "
                @click="isShow = false"
              >
                <div>{{ newsList[1].title }}</div>
                <div class="ellipsis">{{ newsList[1].intro }}</div>
                <div>
                  <span  @click="viewNewsDetail(newsList[1],1)">查看全文 </span>
                  <img src="../../assets/news/jt22.png" alt="" />
                </div>
              </div>
            </div>
            <div>
              <!-- <img src="../../assets/news/t3.png" alt="" /> -->
              <img :src="newsList[2].coverImage" alt="">
              <div class="news-name" @click="showDetail(3)">
                <div>{{ newsList[2].title }}</div>
                <img src="../../assets/news/jt23.png" alt="" />
              </div>
              <div
                :class="
                  index === 3 && isShow
                    ? 'view-detail view-detail-show'
                    : 'view-detail'
                "
                @click="isShow = false"
              >
                <div>{{ newsList[2].title }}</div>
                <div class="ellipsis">{{ newsList[2].intro }}</div>
                <div>
                  <span  @click="viewNewsDetail(newsList[2],2)">查看全文 </span>
                  <img src="../../assets/news/jt22.png" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div class="right">
            <!-- <img src="../../assets/news/t4.png" alt="" /> -->
            <img :src="newsList[3].coverImage" alt="">
            <div class="news-name" @click="showDetail(4)">
              <div>{{ newsList[3].title }}</div>
              <img src="../../assets/news/jt23.png" alt="" />
            </div>
            <div
              :class="
                index === 4 && isShow
                  ? 'view-detail view-detail-show'
                  : 'view-detail'
              "
              @click="isShow = false"
            >
              <div>{{ newsList[3].title }}</div>
              <div class="ellipsis">{{ newsList[3].intro }}</div>
              <div>
                <span  @click="viewNewsDetail(newsList[3],3)">查看全文 </span>
                <img src="../../assets/news/jt22.png" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function() { 
  // 将滚动条置于顶部
  setTimeout(function() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, 100);
}
export default {
  data() {
    return {
      isShow: false,
      // 新闻详情出现的序号
      index: 0,
      newsList: [
        {
          title: "新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
        },
        {
          title: "新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
        },
        {
          title: "新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
        },
        {
          title: "新闻名称新闻名称新闻名称",
          content:
            "新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容新闻内容",
        },
      ],
      newsParam:{
        pageNum:1,
        pageSize:10,
      },
    };
  },
  mounted(){
    this.requestNewsList()
  },
  methods: {
    showDetail(i) {
      this.isShow = true;
      this.index = i;
    },
    // 跳转新闻列表页
    viewMore(name) {
      this.$store.commit('changeIndex',4)
      this.$router.push({
        name: name,
      });
    },
    // 跳转新闻详情页
    viewNewsDetail(item,i){
      // 存储新闻详情
      localStorage.setItem('newsDetail',JSON.stringify(item));
      // 存储新闻列表的顺序
      localStorage.setItem('index',i);
      this.$router.push('/newsDetail')
    },
    // 请求新闻列表
    requestNewsList(){
      let param ={
        pageNum: this.newsParam.pageNum,
        pageSize:this.newsParam.pageSize,
      }
      this.$post("/web/new/pageList", param)
        .then((res) => {
          // 主要参数
          if (res.code == 1) {
            // 取数组的前4个
            this.newsList= res.data.list.slice(0,4);
          } else {
          console.log("异常");
          }
        });
    },
  },
};
</script>

<style scoped lang="scss">
.header img {
  width: 100%;
  /* height: auto; */
}

.content {
  padding:1rem 1.6rem;
}
.ellipsis{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 设置最大显示行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}
.title {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #272727;
  cursor: pointer;
}
.news .title {
  margin-top: 0.87rem;
}
.en-title {
  font-size: 0.26rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #909090;
  margin-bottom: 0.58rem;
  cursor: pointer;
  position: relative;
}
.line {
  width: 2.5vw;
  height: 3px;
  background: #127bf2;
  border-radius: 2px;
  margin: 10px 0;
}

.message-content {
  position: relative;
  cursor: pointer;
}
.meeting {
  width: 9rem;
  height: 4.84rem;
  margin-left: -0.12rem;
}
.card {
  position: absolute;
  top: -0.96rem;
  right: 0;
  z-index: -1;
  width: 8rem;
  height: 5.8rem;
  background: #f3f3f3;
}
.word {
  width: 5.96rem;
  height: 2.49rem;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #272727;
  line-height: 0.36rem;
  margin-top: 0.8rem;
  margin-left: 1.18rem;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}
.card {
  .img {
    width: 2.27rem;
    height: 1.21rem;
    position: absolute;
    bottom: 0.26rem;
    right: 1.04rem;
  }
}
.sign span {
  margin-left: 4.33rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #272727;
  line-height: 0.33rem;
}

.sign {
  display: flex;
  img {
    width: 1rem;
    margin-left: .33rem;
  }
}

.more {
  position: absolute;
  right: 0;
  bottom: 0;
}
.more > div:nth-child(1) {
  font-size: 0.18rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #127bf2;
  line-height: 0.25rem;
}
.more .line {
  width: 0.86rem;
  height: 0.02rem;
  background: #b7b7b7;
}
.more .line:hover {
  background: #127bf2;
}

.news-content {
  display: flex;
}

.left {
  width: 4.07rem;
  height: 6.9rem;
  position: relative;
  overflow: hidden;
}
.middle {
  margin: 0 0.4rem;
}
.middle > div {
  width: 4.73rem;
  height: 3.24rem;
  position: relative;
  overflow: hidden;
}
.middle > div:nth-child(1) {
  margin-bottom: 0.4rem;
}
.right {
  width: 6.39rem;
  height: 6.9rem;
  position: relative;
  overflow: hidden;
}
.left > img,
.right > img,
.middle > div > img {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}
.news-name {
  width: 100%;
  height: 0.56rem;
  margin: auto;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding-left: 0.18rem;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  cursor: pointer;
}
.news-name > div {
  width: 90%;
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 0.24rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.news-name > img {
  width: 0.14rem;
  height: 0.08rem;
}

.view-detail {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 0.2rem;
  background: rgba(18, 123, 242, 0.85);
  position: absolute;
  bottom: -100%;
  z-index: 10;
  cursor: pointer;
  overflow: hidden;
  transform: translateY(0);
  transition: all 1s;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.view-detail > div:nth-child(1) {
  font-size: 0.18rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
}
.view-detail > div:nth-child(2) {
  font-size: 0.16rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.83);
  line-height: 0.32rem;
  margin: 0.3rem 0;
  letter-spacing: 0.01rem;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}
.view-detail > div:nth-child(3) {
  font-size: 0.2rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  /* font-weight: 600; */
  color: #ffffff;
  text-align: center;
}
.view-detail-show {
  transform: translateY(-100%);
}
</style>
